import React, { useCallback, useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { FlatList, TextInput } from "react-native-web";

const allFonts = {
  headerText: {
    fontSize: 24,
    fontFamily: "Figtree-Bold",
  },
  titleText: {
    fontSize: 18,
    fontFamily: "Figtree-Medium",
  },
  bodyText: {
    fontSize: 16,
    fontFamily: "Figtree-Regular",
  },
  buttonLabelText: {
    fontSize: 16,
    fontFamily: "Figtree-Medium",
  },
  uiLabelText: {
    fontSize: 14,
    fontFamily: "Figtree-Regular",
  },
  captionTitleText: {
    fontSize: 14,
    fontFamily: "Figtree-Medium",
  },
  captionSubText: {
    fontSize: 12,
    fontFamily: "Figtree-Regular",
  },
  sixteenAndBold: {
    fontSize: 16,
    fontFamily: "Figtree-Bold",
  },
  inputFieldLabel: {
    // Custom added
    fontSize: 12,
    fontFamily: "Figtree-Light",
  },
  semiBoldSixteen: {
    fontSize: 16,
    fontFamily: "Figtree-SemiBold",
  },
};

const allColors = {
  primaryAccent: "#64C0FF",
  primaryAccentText: "#2B3741",

  secondaryAccent: "#CEA667",
  secondaryAccentText: "#2B3741",

  primaryBackground: "#09141A",
  primaryText: "#FFFFFF",

  secondaryText: "#8E8E8E",
  secondarySubText: "#606971",

  colorFillA: "#40505F",
  colorFillB: "#384754",

  greenFill: "#34E0A1",
  greenFillAtext: "#09151A",

  redFill: "#D76161",
  redFillText: "#09151A",

  // eslint-disable-next-line
  primaryAccent_DarkTheme: "#64C0FF",
  // eslint-disable-next-line
  primaryAccentText_DarkTheme: "#FFFFFF",

  // eslint-disable-next-line
  secondaryAccent_DarkTheme: "#CEA667",
  // eslint-disable-next-line
  secondaryAccentText_DarkTheme: "#2B3741",

  // eslint-disable-next-line
  primaryBackground_DarkTheme: "#FAFBFB",
  // eslint-disable-next-line
  primaryText_DarkTheme: "#1D1D1D",

  // eslint-disable-next-line
  secondaryText_DarkTheme: "#EBEDEF",
  // eslint-disable-next-line
  secondarySubText_DarkTheme: "#1D1D1D",

  // eslint-disable-next-line
  colorFillA_DarkTheme: "#40505F",
  // eslint-disable-next-line
  colorFillB_DarkTheme: "#384754",

  // eslint-disable-next-line
  greenFill_DarkTheme: "#34E0A1",
  // eslint-disable-next-line
  greenFillAtext_DarkTheme: "#09151A",

  // eslint-disable-next-line
  redFill_DarkTheme: "#D76161",
  // eslint-disable-next-line
  redFillText_DarkTheme: "#09151A",
};

function App() {
  const [suspectColorKeys, setSuspectColorKeys] = useState([]);
  const [fontProps, setFontProps] = useState({ fontFam: "-", fontSize: 0 });
  const [suspectFontKeys, setSuspectFontKeys] = useState([]);

  const refreshSearch = useCallback((fontProps) => {
    const resObj = findFontSize(fontProps.fontSize);
    findFontFam(fontProps.fontFam, resObj);
  }, []);

  useEffect(() => {
    refreshSearch(fontProps);
  }, [fontProps, refreshSearch]);

  const findColor = (text) => {
    let tempObjArr = [];
    if (text.length > 0) {
      Object.keys(allColors).forEach(function (key) {
        var value = allColors[key];
        if (value.toLowerCase().includes(text.toLowerCase())) {
          tempObjArr.push({ colorHex: value, colorKey: key });
        }
      });
    } else {
      setSuspectColorKeys([]);
    }
    setSuspectColorKeys(tempObjArr);
  };

  const findFontFam = (text, obj) => {
    let tempObjArr = [];
    if (text.length > 0) {
      Object.keys(obj).forEach(function (key) {
        var value = obj[key];
        if (value?.fontFamily?.toLowerCase().includes(text.toLowerCase())) {
          tempObjArr.push({ ...value, fontKey: key });
        } else {
          setSuspectFontKeys([]);
        }
      });
    } else {
      setSuspectFontKeys([]);
    }
    setSuspectFontKeys(tempObjArr);
  };

  const findFontSize = (text) => {
    let tempObj = {};
    Object.keys(allFonts).forEach(function (key) {
      var value = allFonts[key];
      if (value.fontSize === +text) {
        tempObj = { ...tempObj, [`${key}`]: value };
      }
    });
    return tempObj;
  };

  const _onFontPropInput = (fam, size) => {
    fam?.length
      ? setFontProps((prev) => ({
          fontSize: prev.fontSize,
          fontFam: fam || "-",
        }))
      : size > 0 &&
        setFontProps((prev) => ({ fontFam: prev.fontFam, fontSize: size }));
  };

  const FontRow = ({ item }) => {
    return (
      <View style={styles.fontRowView}>
        <Text style={styles.fontText}>fontSize: {item.fontSize}</Text>
        <Text style={styles.fontText}>fontFamily: {item.fontFamily}</Text>
        <Text style={styles.fontText}>fontKey: {item.fontKey}</Text>
      </View>
    );
  };
  const ColorRow = ({ item }) => {
    return (
      <View style={styles.fontRowView}>
        <Text style={styles.fontText}>colorHex: {item.colorHex}</Text>
        <Text style={styles.fontText}>
          colorKey: {item.colorKey.replace("_", " - ")}
        </Text>
      </View>
    );
  };

  const NoResults = () => {
    return (
      <Text
        style={[
          styles.fontText,
          {
            color: "#969696",
            textAlign: "center",
            marginTop: 25,
          },
        ]}
      >
        No Results
      </Text>
    );
  };

  return (
    <View style={styles.app}>
      <View style={styles.header}>
        <Text style={styles.title}>KOGO Style Detective</Text>
        <Text style={styles.subHeader}>Search karo aur jeeto 1 KOGO-Coin</Text>
      </View>
      <View style={styles.titleCont}>
        <Text style={styles.text}>Color Search</Text>
        <Text
          onPress={() => setSuspectColorKeys([])}
          style={[
            styles.fontText,
            {
              color: "red",
              textDecorationLine: "underline",
            },
          ]}
        >
          Reset
        </Text>
      </View>
      <View style={styles.SearchView}>
        <View
          style={[
            styles.textInputView,
            {
              flexDirection: "row", flex: 0.4
            },
          ]}
        >
          <Text style={styles.text}>#</Text>
          <TextInput
            style={[
              styles.textInput,
              {
                marginTop: 2,
              },
            ]}
            placeholder="Color HEX"
            placeholderTextColor="#696969"
            onChangeText={(text) => findColor(text)}
            maxLength={6}
          />
        </View>
        <FlatList
          data={suspectColorKeys}
          renderItem={({ item }) => <ColorRow item={item} />}
          keyExtractor={(item) => item.fontKey}
          style={styles.flatListStyle}
          ListEmptyComponent={NoResults}
        />
      </View>
      <View style={styles.line} />
      <View style={styles.titleCont}>
        <Text style={styles.text}>Font Search</Text>
        <Text
          onPress={() => setSuspectFontKeys([])}
          style={[
            styles.fontText,
            {
              color: "red",
              textDecorationLine: "underline",
            },
          ]}
        >
          Reset
        </Text>
      </View>

      <View style={styles.SearchView}>
        <View style={{flex: 0.7}}>
          <View style={styles.textInputView}>
            <TextInput
              style={styles.textInput}
              placeholder="Font Size (Required)"
              placeholderTextColor="#696969"
              onChangeText={(text) => _onFontPropInput(null, text)}
            />
          </View>
          <View
            style={[
              styles.textInputView,
              {
                marginTop: 15,
              },
            ]}
          >
            <TextInput
              style={styles.textInput}
              placeholder="Font Family"
              placeholderTextColor="#696969"
              onChangeText={(text) => _onFontPropInput(text, null)}
            />
          </View>
        </View>
        <FlatList
          data={suspectFontKeys}
          renderItem={({ item }) => <FontRow item={item} />}
          keyExtractor={(item) => item.fontKey}
          style={styles.flatListStyle}
          ListEmptyComponent={NoResults}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  app: {
    marginHorizontal: "auto",
    maxWidth: 500,
    flex: 1,
    width: "100%",
    paddingHorizontal: 15
  },
  header: {
    paddingVertical: 10,
  },
  title: {
    fontWeight: "bold",
    fontSize: "1.5rem",
    marginVertical: "1em",
    textAlign: "center",
  },
  text: {
    lineHeight: "1em",
    fontSize: "1.125rem",
    marginVertical: "1em",
  },
  fontText: {
    lineHeight: "1em",
    fontSize: "0.8rem",
    marginBottom: 5,
  },
  textInputView: {
    borderWidth: 1,
    padding: 5,
    borderRadius: 5,
    height: 35,
    alignItems: "center",
    justifyContent: "center",
  },
  textInput: {
    width: "100%",
    outlineStyle: "none",
  },
  line: {
    width: "100%",
    height: 1,
    backgroundColor: "#696969",
    marginTop: 15,
  },
  subHeader: {
    fontWeight: "light",
    fontSize: "0.8rem",
    textAlign: "center",
    color: "#696969",
  },
  fontRowView: {
    marginHorizontal: 15,
    marginVertical: 8,
  },
  titleCont: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  SearchView: {
    flexDirection: "row",
    height: 125,
  },
  flatListStyle: {
    marginLeft: 15,
    borderWidth: 1,
    borderColor: "#969696",
    borderRadius: 5,
    flex: 1,
  },
});

export default App;
